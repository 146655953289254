import { MessageCircle, X, Send } from 'lucide-react';
import React, { useState, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import { API_URL } from './constants';

const ChatWidget = ({isOpen, setIsOpen}) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [userInfo, setUserInfo] = useState({
    name: '',
    email: ''
  });
  const [isUserInfoSubmitted, setIsUserInfoSubmitted] = useState(false);

  const sendMessageToServer = async (messageList) => {
    if (userInfo.email && messageList.length > 0) {
      try {
        const response = await fetch(`${API_URL}/api/send-chat`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            chat_email_address: userInfo.email,
            chat_user_name: userInfo.name,
            messages: messageList,
            timestamp: new Date().toISOString()
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to send chat transcript');
        }
      } catch (error) {
        console.error('Error sending chat transcript:', error);
      }
    }
  };

  const handleUserInfoSubmit = (e) => {
    e.preventDefault();
    if (userInfo.name && userInfo.email) {
      setIsUserInfoSubmitted(true);
      const welcomeMessage = {
        text: `Welcome ${userInfo.name}! How can we help you today?`,
        sender: 'bot'
      };
      setMessages([welcomeMessage]);
      // sendMessageToServer([welcomeMessage]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newMessage.trim()) {
      const userMessage = { text: newMessage, sender: 'user' };
      const botMessage = {
        text: "Thanks for reaching out! We'll get back to you soon.",
        sender: 'bot'
      };

      // Clear input immediately
      setNewMessage('');

      // Update messages with user's message
      const updatedMessages = [...messages, userMessage];
      setMessages(updatedMessages);

      // Add bot response after a delay
      setTimeout(async () => {
        const finalMessages = [...updatedMessages, botMessage];
        setMessages(finalMessages);
        
        // Send the complete conversation to server
        await sendMessageToServer(finalMessages);
      }, 500);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    // Reset the chat state when closing
    setMessages([]);
    setNewMessage('');
    setIsUserInfoSubmitted(false);
    setUserInfo({ name: '', email: '' });
  };
  
    return (
      <>
        {/* Chat Toggle Button */}
        <motion.button
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          whileHover={{ scale: 1.1 }}
          onClick={() => setIsOpen(true)}
          className={`fixed bottom-6 right-6 bg-blue-600 text-white p-4 rounded-full shadow-lg z-50 ${isOpen ? 'hidden' : ''}`}
        >
          <MessageCircle size={24} />
        </motion.button>
  
        {/* Chat Window */}
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: isOpen ? 1 : 0, y: isOpen ? 0 : 100 }}
          className={`fixed bottom-6 right-6 w-96 bg-white rounded-lg shadow-xl z-50 ${isOpen ? '' : 'hidden'}`}
        >
          {/* Chat Header */}
          <div className="bg-blue-600 text-white p-4 rounded-t-lg flex justify-between items-center">
            <h3 className="font-semibold">Send your Query</h3>
            <button onClick={handleClose} className="hover:text-gray-200">
              <X size={20} />
            </button>
          </div>
  
          {!isUserInfoSubmitted ? (
            // User Info Form
            <form onSubmit={handleUserInfoSubmit} className="p-4">
              <div className="space-y-4">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={userInfo.name}
                    onChange={(e) => setUserInfo(prev => ({ ...prev, name: e.target.value }))}
                    className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={userInfo.email}
                    onChange={(e) => setUserInfo(prev => ({ ...prev, email: e.target.value }))}
                    className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors"
                >
                  Start Chat
                </button>
              </div>
            </form>
          ) : (
            <>
              {/* Chat Messages */}
              <div className="h-96 overflow-y-auto p-4 bg-gray-50">
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`mb-4 ${message.sender === 'user' ? 'text-right' : 'text-left'}`}
                  >
                    <div
                      className={`inline-block p-3 rounded-lg ${
                        message.sender === 'user'
                          ? 'bg-blue-600 text-white'
                          : 'bg-gray-200 text-gray-800'
                      }`}
                    >
                      {message.text}
                    </div>
                  </div>
                ))}
              </div>
  
              {/* Chat Input */}
              <form onSubmit={handleSubmit} className="p-4 border-t">
                <div className="flex gap-2">
                  <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type your message..."
                    className="flex-grow p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                  />
                  <button
                    type="submit"
                    className="bg-blue-600 text-white p-2 rounded-lg hover:bg-blue-700"
                  >
                    <Send size={20} />
                  </button>
                </div>
              </form>
            </>
          )}
        </motion.div>
      </>
    );
  };

  export default ChatWidget;