import React from 'react';
import { Grid, Typography, Button, Box, Card, Fade } from '@mui/material';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";

const GridComponent = ({rowData, colDefs}) => {

  const pagination = true;

  // sets 10 rows per page (default is 100)
  const paginationPageSize = 10;

  // allows the user to select the page size from a predefined list of page sizes
  const paginationPageSizeSelector = [10, 20, 30, 50];
  
  return (
      <div
      className="ag-theme-quartz" // applying the grid theme
      style={{ height: 400 }} // the grid will fill the size of the parent container
    >
      <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          pagination={pagination}
          paginationPageSize={paginationPageSize}
          paginationPageSizeSelector={paginationPageSizeSelector}
      />
    </div>
  // </div>
  );
};

export default GridComponent;