import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { motion } from 'framer-motion';
import { MessageCircle, X, Download, Send } from 'lucide-react';
import { API_URL } from './constants';

// Dataset Card Component
const DatasetCard = ({ title, description, size, downloads, filename, user }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const handleDownload = () => {
    // In a real implementation, you would:
    // 1. Check if user is authenticated
    // 2. If not, redirect to /signin
    // 3. If yes, trigger download
    navigate("/cdatasets");
    // if(user === null){
      //  window.location.href = '/signin';
   // }
    // else{

    // }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ scale: 1.02 }}
      className="bg-white rounded-xl shadow-lg p-6 transition-all duration-300"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex flex-col h-full">
        <h3 className="text-xl font-semibold text-blue-600 mb-3">{title}</h3>
        <p className="text-gray-600 mb-4 flex-grow">{description}</p>
        <div className="flex justify-between items-center text-sm text-gray-500 mb-4">
          {/* <span>{size}</span> */}
          {/* <span>{downloads} downloads</span> */}
        </div>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleDownload}
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg flex items-center justify-center gap-2 hover:bg-blue-700 transition-colors"
        >
          <Download size={20} />
          Download Dataset
        </motion.button>
      </div>
    </motion.div>
  );
};

// Community Datasets Section
const CommunityDatasets = () => {
  const datasets = [
    {
      title: "Customer Support Dataset",
      description: "Frequently Asked Questions and corresponding responses specific to e-commerce platforms, designed to enhance customer support.",
      size: "2.3 GB",
      downloads: "1.2K",
      filename: "customer_support_dataset.csv"
    },
    {
      title: "E-commerce Product Reviews",
      description: "High-quality product reviews dataset with sentiment labels Positive, Negative and Neutral, ideal for sentiment analysis and recommendation systems.",
      size: "1.8 GB",
      downloads: "856",
      filename: "ecommerce_reviews.csv"
    },
    {
      title: "Scam Classification Dataset",
      description: "Labeled examples of scam and genuine messages in the Telugu language, aimed at training models for accurate detection and classification of scam content.",
      size: "3.1 GB",
      downloads: "642",
      filename: "medical_reports.csv"
    }
  ];

  return (
    <div className="py-20">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl font-bold text-blue-600 mb-4">Community Datasets</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Download high-quality synthetic datasets created by our community. 
            We are adding new datasets for niche usecases everyday.
          </p>
        </motion.div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {datasets.map((dataset, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <DatasetCard {...dataset} />
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { CommunityDatasets };