import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Checkbox,
  FormControlLabel,
  Box
} from '@mui/material';

const TermsDialog = ({ open, handleClose, handleAgree }) => {
  const [checked, setChecked] = useState(false);

  // Reset checkbox when the dialog is reopened
  useEffect(() => {
    if (open) {
      setChecked(false);
    }
  }, [open]);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleAgreeClick = () => {
    if (checked) {
      handleAgree();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Terms and Conditions</DialogTitle>
      <DialogContent>
        <DialogContentText>
          By downloading the file, you agree to the following:
          <Box component="ol" sx={{ paddingLeft: '20px', marginTop: '10px' }}>
            <li><b>Ethical Use:</b> Use the data only for ethical and legal purposes.</li>
            <li><b>User Responsibility:</b> You are solely responsible for your use of the data.</li>
            <li><b>Liability:</b> We are not liable for any consequences resulting from data use or misuse.</li>
            <li><b>No Endorsement:</b> Providing this data does not endorse any specific use or interpretation.</li>
            <li><b>Serious Applications:</b> For medical, legal, or health-related uses, verify with a qualified professional first.</li>
          </Box>
        </DialogContentText>
      </DialogContent>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleCheckboxChange} />}
        label="I accept the Terms and Conditions"
        sx={{ marginLeft: '20px', marginTop: '-10px' }}
      />
      <DialogActions sx={{ paddingRight: '20px', paddingBottom: '10px' }}>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleAgreeClick} color="primary" disabled={!checked}>
          I Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsDialog;
