import React from 'react';
import { Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const PricingCard = ({ title, price, features, buttonText, isPopular, customPrice, onButtonClick }) => (
  <div className={`relative h-full flex flex-col rounded-2xl bg-white p-6 ${
    isPopular ? 'border-2 border-blue-500 shadow-lg' : 'border border-gray-200'
  }`}>
    {isPopular && (
      <div className="absolute right-4 top-4">
        <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-500 text-white">
          Most Popular
        </span>
      </div>
    )}
    
    <div className="mb-8">
      <h3 className="text-2xl font-bold text-blue-600 mb-2">{title}</h3>
      <button
        onClick={onButtonClick}
        className="w-full py-3 px-6 rounded-full bg-blue-600 text-white font-semibold 
                 hover:bg-blue-700 transition-colors duration-200 ease-in-out
                 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        {buttonText}
      </button>
      {/* <div className="flex items-baseline gap-1">
        <span className="text-4xl font-bold text-gray-900">
          {customPrice ? price : `$${price}`}
        </span>
        {!customPrice && (
          <span className="text-gray-500 text-sm">/ month</span>
        )}
      </div> */}
    </div>

    <div className="flex flex-col flex-grow">
      <ul className="space-y-4 mb-8 flex-grow">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center gap-2">
            <Check className="h-5 w-5 text-blue-500" />
            <span className="text-gray-600">{feature}</span>
          </li>
        ))}
      </ul>
    </div>
  </div>
);


const PricingSection = ({ onProButtonClick }) => {

  const navigate = useNavigate();
  const pricingData = [
    {
      title: 'Free',
      price: '0',
      features: [
        '10K English Data Points/month',
        '1K Other Language Data Points/month',
        'CSV Download',
        'File Upload upto 50 KB',
        'Custom Column Names',
        'Access to Community Datasets',
        'Limited Email Support'
      ],
      buttonText: 'Get started',
      onButtonClick: () => navigate("/signin"),
    },
    {
      title: 'Pro',
      price: 'Custom',
      features: [
        'All Free tier features',
        '100K English Data Points/month',
        '10K Other Language Data Points/month',
        'Private Datasets',
        'Multimodal Data Generation',
        'File Upload upto 1GB',
        'Web Search Integration',
        'Dataset Review by AI'
      ],
      buttonText: 'Contact us',
      onButtonClick: onProButtonClick,
      isPopular: false,
    },
    {
      title: 'Enterprise',
      price: 'Custom',
      customPrice: true,
      features: [
        'For user teams',
        'Unlimited Number of Data Points',
        'Onboarding & Training',
        'Personalized Experts for Data Review',
        'Custom Data Format Downloads',
        'Additional Project and Fine-Tuning Guidance',
        'Everything in Pro, Free',
        '24x7 Email support'
      ],
      buttonText: 'Contact us',
      onButtonClick: onProButtonClick,
    }
  ];

  return (
    <section className="py-20 px-4 bg-gradient-to-b from-blue-50 to-white">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-blue-600 mb-4">Pricing</h2>
          <p className="text-gray-600 text-lg">
            Choose the perfect plan for your data generation needs.
          </p>
        </div>
        
        <div className="grid md:grid-cols-3 gap-8">
          {pricingData.map((plan, index) => (
            <PricingCard key={index} {...plan} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default PricingSection;