import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const TermsAndPrivacy = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/signin');
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '20px',
    maxWidth: '800px',
    margin: 'auto',
    fontFamily: 'Arial, sans-serif',
  };

  const contentStyle = {
    margin: '20px',
    textAlign: 'center',
  };

  const headingStyle = {
    marginTop: '20px',
    marginBottom: '10px',
  };

  const paragraphStyle = {
    textAlign: 'left',
    margin: '0 auto 20px auto',
  };

  const ulStyle = {
    listStyleType: 'disc',
    paddingLeft: '20px',
    textAlign: 'left',
    margin: '0 auto 20px auto',
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <h1 style={headingStyle}>Terms of Use</h1>
        <p style={paragraphStyle}>Effective Date: 1st July, 2024</p>

        <h2 style={headingStyle}>1. Acceptance of Terms</h2>
        <p style={paragraphStyle}>
          By accessing and using DataCreator AI (the "Service"), you agree to comply with these Terms of Use ("Terms"). If you do not agree, you must discontinue using the Service.
        </p>

        <h2 style={headingStyle}>2. Use of Service</h2>
        <p style={paragraphStyle}>
          The Service is designed for generating synthetic data for machine learning and AI purposes. You agree not to misuse, reverse-engineer, or attempt to bypass the Service's protections. The following is strictly prohibited:
        </p>
        <ul style={ulStyle}>
          <li><b>Explicit Content:</b> Generation of explicit, NSFW, vulgar, or offensive content is prohibited. Violation will result in account suspension or termination.</li>
          <li><b>Ethical Use:</b> Use the data only for ethical and legal purposes.</li>
          <li><b>User Responsibility:</b> You are solely responsible for your use of the data.</li>
          <li><b>Liability:</b> We are not liable for any consequences resulting from data use or misuse.</li>
          <li><b>No Endorsement:</b> Providing this data does not endorse any specific use or interpretation.</li>
          <li><b>Serious Applications:</b> For medical, legal, or health-related uses, verify with a qualified professional first.</li>
        </ul>

        <h2 style={headingStyle}>3. External APIs and Third-Party Services</h2>
        <p style={paragraphStyle}>
          We integrate third-party APIs (e.g., Serper API, Claude Haiku, Google Translate API) for enhanced functionalities. Your use of these third-party services is subject to their respective terms and conditions.
        </p>

        <h2 style={headingStyle}>4. Account and Data Security</h2>
        <p style={paragraphStyle}>
          You agree to provide accurate information when creating an account. Google Signup information will be securely stored and handled in compliance with Indian data protection laws (IT Act, 2000, and IT Rules, 2011).
        </p>

        <h2 style={headingStyle}>5. Termination</h2>
        <p style={paragraphStyle}>We reserve the right to suspend or terminate accounts that violate these Terms.</p>

        <h2 style={headingStyle}>6. Governing Law</h2>
        <p style={paragraphStyle}>These Terms are governed by Indian law and subject to the jurisdiction of Indian courts.</p>

        <h2 style={headingStyle}>7. Email Subscription</h2>
        <p style={paragraphStyle}>You agree to recieve emails about important updates and new features. You can unsubscribe any time.</p>

        <h1 style={headingStyle}>Privacy Policy</h1>
        <p style={paragraphStyle}>Effective Date: 1st July, 2024</p>

        <h2 style={headingStyle}>1. Data Collection</h2>
        <p style={paragraphStyle}>We collect the following information:</p>
        <ul style={ulStyle}>
          <li>Google account information (used for signing in)</li>
          <li>Synthetic data generated via external APIs</li>
        </ul>

        <h2 style={headingStyle}>2. Use of Collected Data</h2>
        <p style={paragraphStyle}>Your data is used to operate the Service and improve user experience. No personal data is shared with third parties without consent.</p>

        <h2 style={headingStyle}>3. Data Security</h2>
        <p style={paragraphStyle}>
          We implement reasonable security practices as per the IT Act (2000) and IT Rules (2011) to protect your data. All stored data is encrypted and securely managed.
        </p>

        <h2 style={headingStyle}>4. Third-Party Services</h2>
        <p style={paragraphStyle}>
          Our platform uses external APIs for data generation. Your interactions with these services are governed by their respective privacy policies and terms.
        </p>

        <h2 style={headingStyle}>5. User Rights</h2>
        <p style={paragraphStyle}>You have the right to access, modify, or delete your personal information. Requests can be made via our contact form.</p>

        <h2 style={headingStyle}>6. Data Retention</h2>
        <p style={paragraphStyle}>We retain user data for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required by law.</p>

        <h2 style={headingStyle}>7. Changes to this Policy</h2>
        <p style={paragraphStyle}>We reserve the right to modify this Privacy Policy at any time with prior notice.</p>

        <h2 style={headingStyle}>8. Governing Law</h2>
        <p style={paragraphStyle}>This Privacy Policy is governed by the laws of India.</p>
        
        <Button variant="outlined" onClick={handleGoBack} sx={{ mt: 4 }}>
          Go Back to Sign In Page
        </Button>
      </div>
    </div>
  );
};

export default TermsAndPrivacy;
