import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA2AdfQumZMNG5OJZ7T5WOtNknK5xTs1Kw",
  authDomain: "datagenerator-425908.firebaseapp.com",
  projectId: "datagenerator-425908",
  storageBucket: "datagenerator-425908.appspot.com",
  messagingSenderId: "111535837563",
  appId: "1:111535837563:web:9d3723c12dad0a33f3adde"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider };